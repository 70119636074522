.daily-quote img {
    width: 35%;
    height: 35%;
    margin-bottom: 1.5rem;
}

.daily-quote p {
    text-align: center;
    font-weight: 300;
}

@media all and (max-width:767px) {

    .daily-quote img {
        width: 25%;
        height: 25%;
        margin-bottom: 1rem;
    }

    .daily-quote p {
        font-size: 12px;
    }

}