.login {
    height: 100vh;
    padding: 0;
    background-color: #f8f9fa;

    & .row,
    & .row .col {
        height: 100%;
    }

    &.ar {
        direction: rtl;
    }
}

.login.ar h2,
.login.ar p,
.login.ar label,
.login.ar button {
    font-family: "Noor";

}