.modal .text-error {
    margin-top: 5px;
    color: var(--dash-head-color);
}

.modal.ar .modal-header {
    justify-content: space-between;

    & button {
        margin: 0px;
    }
}

.modal .btn {
    background-color: unset;
    border: none;
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);

    &:hover {
        background-image: linear-gradient(to right, var(--primary-color), #06626e57);
        color: white;
        transition: .3s;
    }
}

.show-box {
    align-items: flex-start;
}

.show-box.show-box-leave p {
    width: 50%;
}

.show-box.show-box-leave label {
    width: 50%;
}

.show-box.show-box-outdoor-task p {
    width: 80%;
}

.show-box.show-box-outdoor-task label {
    width: 27%;
}

.show-box p {
    width: 89%;
    word-break: break-all;
    color: var(--dash-head-color);
}

.show-box label {
    width: 17%;
}

.show-box-leads.show-box label {
    width: 47%;
}


.modal label {
    color: var(--dash-secondary-primary);
    font-size: 14px;
    font-weight: 600;
    margin-bottom: .5rem;
}

.modal-body {
    padding: 25px 30px !important;
}

/* Notification Modal */
.notification-modal.ar {
    left: 0;
    right: unset;
    padding-right: unset !important;
    padding-left: 0 !important;
}

.notification-modal {
    width: unset;
    right: 0px;
    padding-right: 0px !important;
    left: unset;
}

.notification-modal .modal-fullscreen {
    width: 40vw;
}

.notification-modal.show .modal-dialog {
    transform: none !important;
}

.notification-modal.ar.fade .modal-dialog {
    transform: translate(-50px, 0)
}

.notification-modal.fade .modal-dialog {
    transform: translate(50px, 0)
}

/* Notification Modal */