.main-btn {
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
    padding: 5px 20px;
    font-size: 12px;
    color: white;
    border: 0px;
}

.main-btn:hover {
    background-image: linear-gradient(to right, var(--primary-color), #06626e57);
    color: white;
    transition: .3s;
}

@media all and (max-width:767px) {
    .main-btn {
        padding: 5px 10px;
        font-size: 10px;
    }
}