/* Open Sans */
@font-face {
    font-family: "Open Sans";
    font-weight: 300;
    src: url(../Fonts/OpenSans/OpenSans-Light.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 400;
    src: url(../Fonts/OpenSans/OpenSans-Regular.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 500;
    src: url(../Fonts/OpenSans/OpenSans-Medium.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 600;
    src: url(../Fonts/OpenSans/OpenSans-SemiBold.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 700;
    src: url(../Fonts/OpenSans/OpenSans-Bold.ttf);
}

@font-face {
    font-family: "Open Sans";
    font-weight: 800;
    src: url(../Fonts/OpenSans/OpenSans-ExtraBold.ttf);
}

/* Noor */
@font-face {
    font-family: "Noor";
    font-weight: 300;
    src: url(../Fonts/Noor/Noor\ Regular.ttf)
}