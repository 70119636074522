.profile-banner .tabs a {
    display: inline-block;
    padding-bottom: 1rem;
    font-size: 15px;
    font-weight: 700;
    color: var(--dash-secondary-primary);
}

.profile-banner .tabs a:hover,
.profile-banner .tabs a.active {
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
}

.profile-banner.ar .tabs li:not(:last-of-type) {
    margin-right: 0px;
    margin-left: 1.5rem;
}

.profile-banner .tabs li:not(:last-of-type) {
    margin-right: 1.5rem
}

@media all and (max-width:767px) {
    .profile-banner .tabs a {
        padding-bottom: .3rem;
        font-size: 10px;
    }

    .profile-banner.ar .tabs li:not(:last-of-type) {
        margin-right: 0px;
        margin-left: 1rem;
    }

    .profile-banner .tabs li:not(:last-of-type) {
        margin-right: 1rem
    }
}