.profile-details-box {
    padding-top: 2.25rem;
}

.profile-details-box .row {
    width: 90%;
}

.profile-details-box .row:not(:last-of-type) {
    margin-bottom: 1.75rem;
}

.profile-details-box h6,
.profile-details-box p,
.profile-details-box label {
    font-weight: 700;
    font-size: 13px;
}

.profile-details-box h6,
.profile-details-box label {
    color: var(--dash-secondary-primary);
}

.profile-details-box p {
    color: var(--dash-head-color);
}

@media all and (max-width:767px) {
    .profile-details-box {
        padding-top: .5rem;
    }

    .profile-details-box h6,
    .profile-details-box p,
    .profile-details-box label {
        font-size: 11px;
    }

    .profile-details-box .row {
        width: 100%;
    }

}