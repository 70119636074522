.add-employer-of-the-month-box {
    margin-top: 4.7%;
}

.add-employer-of-the-month-box h4 {
    color: var(--dash-head-color);
}

.add-employer-of-the-month-box div.form-control {
    border: none;
    width: 100%;
}

.add-employer-of-the-month-box textarea {
    height: 100px;
}

.add-employer-of-the-month-box input,
.add-employer-of-the-month-box select,
.add-employer-of-the-month-box textarea {
    background-color: #c1d0df;
}

.add-employer-of-the-month-box form {
    width: 70%;
}

.add-employer-of-the-month-box form .col {
    display: flex;
    align-items: center;
}

.add-employer-of-the-month-box .btn-form-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.add-employer-of-the-month-box .btn {
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
    padding: 5px 20px;
    font-size: 12px;
    color: white;
    border: 0px;
}

@media all and (max-width:767px) {
    .add-employer-of-the-month-box form {
        width: 100%;
    }

    .add-employer-of-the-month-box input,
    .add-employer-of-the-month-box select,
    .add-employer-of-the-month-box textarea,
    .add-employer-of-the-month-box form .col h6 {
        font-size: 8px;
    }

    .add-employer-of-the-month-box textarea {
        height: 60px;
    }

    .add-employer-of-the-month-box .btn {
        font-size: 8px;
    }
}