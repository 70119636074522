.welcome-to-dashboard {
    padding: 1rem;
    background-image: linear-gradient(to right, rgba(6, 98, 110, 0.4), rgba(6, 98, 110, 0.4)), url(../../../Assets/Images/Dashboard/SideBar/white-curved.jpg);
    width: 60%;
    height: 20%;
    border-radius: 1rem;
}

.waving-hand-con {
    width: 40px;
    height: 40px;
    background-color: white;
    border-radius: .5rem;
    margin-bottom: 1rem;
}

.waving-hand-con img {
    width: 65%;
    height: 65%;
}

.welcome-to-dashboard h6 {
    font-family: 600;
    color: white;
}

@media all and (max-width:767px) {
    .welcome-to-dashboard {
        width: 40%;
        height: 10%;
        padding: 1rem .5rem;
    }

    .waving-hand-con {
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: .5rem;
        margin-bottom: .5rem;
    }

    .welcome-to-dashboard h6 {
        font-size: 12px;
    }

}