:root {
    --primary-color: #0095a9;
    --secondary-color: #A8804E;
    --bg-white: #f8f9fa;
    --bg-primary-text: #f8f9fa;
    --bg-white-text: #0095a9;
    --dash-text-primary: #67748e;
    --dash-secondary-primary: #8392ab;
    --dash-head-color: #344767;
    --toastify-color-progress-light: linear-gradient(to right, var(--primary-color), #06626e8a);
}

ul {
    list-style: none;
}

body {
    overflow-x: hidden;
    overflow: visible;
    font-family: "Open Sans";
    background-color: var(--bg-white);
}

body.ar {
    font-family: "Noor";
}

.bg-full {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-center-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flex-start-center-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.flex-start-center-row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-start-start-col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-col-between {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.flex-between-start {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
}

.flex-start-center {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-center-start-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

input[type="file"] {
    display: none;
}

a {
    text-decoration: none;
    color: unset;
}

.default-img {
    background-image: url(../Images/photo_٢٠٢٤-٠٩-٠٤_١٦-٢١-٠٢.jpg);
    background-size: contain;
}

p {
    margin: 0;
}

:where(.css-dev-only-do-not-override-1c0na6j).ant-pagination .ant-pagination-item-active {
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
    border: unset;
    color: white;
}


@media all and (max-width:767px) {
    .small-screen-margin-b {
        margin-bottom: 20px !important;
    }

    .small-screen-col {
        padding-inline: 0px;
    }

    .box-with-shadow.small-screen-width {
        width: 100% !important;
    }

    :where(.css-dev-only-do-not-override-1c0na6j).ant-table-wrapper .ant-table-cell,
    :where(.css-dev-only-do-not-override-1c0na6j).ant-table-wrapper .ant-table-thead>tr>th,
    :where(.css-dev-only-do-not-override-1c0na6j).ant-table-wrapper .ant-table-tbody>tr>th,
    :where(.css-dev-only-do-not-override-1c0na6j).ant-table-wrapper .ant-table-tbody>tr>td,
    :where(.css-dev-only-do-not-override-1c0na6j).ant-table-wrapper tfoot>tr>th,
    :where(.css-dev-only-do-not-override-1c0na6j).ant-table-wrapper tfoot>tr>td {
        padding: 5px;
        font-size: 6px;
    }

    :where(.css-dev-only-do-not-override-1c0na6j).ant-pagination .ant-pagination-prev,
    :where(.css-dev-only-do-not-override-1c0na6j).ant-pagination .ant-pagination-next,
    :where(.css-dev-only-do-not-override-1c0na6j).ant-pagination .ant-pagination-jump-prev,
    :where(.css-dev-only-do-not-override-1c0na6j).ant-pagination .ant-pagination-jump-next {
        min-width: 5px;
    }

    :where(.css-dev-only-do-not-override-1c0na6j).ant-pagination {
        font-size: 10px;
    }

    .ant-table-cell .dropdown-menu {
        padding: 8px;
        min-width: 4rem;
    }
}