.leads .file-white-con:hover {
    border: 1px solid var(--primary-color);
    transition: .3s;
    cursor: pointer;
    box-sizing: border-box;
}

.leads .border-primary {
    border: 1px solid var(--primary-color);
}

.leads-box p {
    text-align: center;
    font-size: 13px;
    color: var(--dash-text-primary);
}