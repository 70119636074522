.sales-con-flex-col .percentage-circle {
    display: inline-block;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    margin-left: 10px;
}

.sales-con-flex-col .percentage-circle-one {
    background-color: white;
}

.sales-con-flex-col .percentage-circle-two {
    background-color: rgba(255, 255, 255, 0.528);
}

.employe-chart-box.ar .sales-con-flex-col .percentage-circle {
    margin-left: unset;
    margin-right: 10px;
}

.employe-chart-box.ar .sales-con-flex-col {
    margin-right: unset;
    margin-left: 50px;
}

@media all and (max-width:767px) {
    .sales-con-flex-col .percentage-circle {
        width: 10px;
        height: 10px;
    }
}