.holidays-main-con h4 {
    color: var(--dash-head-color);
}

.holidays-buttons .btn:not(:first-of-type) {
    margin-left: 15px;
}

.holidays-buttons.ar .btn:not(:first-of-type) {
    margin-right: 15px;
    margin-left: unset;
}

.fc-h-event .fc-event-title-container {
    cursor: pointer;
}

@media all and (max-width:767px) {
    .holidays-buttons .btn {
        font-size: 5px;
    }

    .holidays-buttons .btn:not(:first-of-type) {
        margin-left: 7px;
    }

    .holidays-buttons.ar .btn:not(:first-of-type) {
        margin-right: 7px;
        margin-left: unset;
    }

    .fc .fc-toolbar-title {
        font-size: .75em;
    }

    .fc .fc-button {
        font-size: 10px;
    }

    .fc .fc-col-header-cell-cushion {
        padding: 2px;
        font-size: 8px;
    }

    .fc .fc-daygrid-day-number,
    .fc-h-event .fc-event-title {
        font-size: 8px;
    }


}