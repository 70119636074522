.profile-banner {
    margin-inline: 0;
    margin-top: 4.7%;
    margin-bottom: 4.7%;
    padding: 30px;
    padding-bottom: 0px;
    width: 96%;
}

.profile-banner.ar .img-profile-banner {
    margin-right: 0px;
    margin-left: 1.75rem;
}

.profile-banner .img-profile-banner {
    width: 160px;
    height: 160px;
    border-radius: 0.475rem;
    margin-right: 1.75rem;
}

.profile-banner .info h2 {
    font-size: 19.5px;
    font-weight: 700;
    color: var(--dash-head-color);
    margin-bottom: 1rem
}

@media all and (max-width:767px) {

    .profile-banner {
        padding: 15px;
        padding-bottom: 0px;
        width: 100% !important;
    }

    .profile-banner .img-profile-banner {
        width: 60px;
        height: 60px;
        margin-right: .5rem;
    }

    .profile-banner.ar .img-profile-banner {
        margin-right: 0px;
        margin-left: .5rem;
    }

    .profile-banner .info h2 {
        font-size: 12px;
    }
}