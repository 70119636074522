.main-dashboard-nav {
    padding-right: 30px;

    &.ar {
        padding-right: 0px;
        padding-left: 30px;
    }
}

.main-dashboard-nav.ar .notification-box {
    margin-right: unset;
    margin-left: 15px;
}

.notification-box {
    position: relative;
    margin-right: 15px;
    cursor: pointer;

    & .icon {
        color: var(--primary-color);
    }
}

.alert-notification {
    position: absolute;
    top: -1px;
    right: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0.733);
}

.burger-icon {
    display: none;
}

@media all and (max-width:767px) {
    .main-dashboard-nav {
        padding-right: 0px;

        &.ar {
            padding-right: 0px;
            padding-left: 0px;
        }
    }

    .burger-icon {
        display: block;
        width: 14%;
        cursor: pointer;

        & .icon {
            color: var(--primary-color);
        }
    }
}