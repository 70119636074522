.login .left-col {
    padding: 2.5rem !important;
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

.login .left-col h2,
.login .left-col p {
    font-weight: 700;
    color: var(--bg-white);
}

.login .left-col p span img {
    width: 25px;
    transform: rotateY(180deg);
}

.sm-logo-con {
    display: none;
}

@media all and (max-width :767px) {
    .login .left-col {
        padding: 1.5rem !important;
    }
}