.main-dashboard-nav h2 {
    color: var(--primary-color);
    font-size: 20px;
    margin: 0px;
}

@media all and (max-width:767px) {
    .main-dashboard-nav h2 {
        font-size: 18px;
    }
}