.file-white-con {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem
}

.file-white-con h2 {
    font-weight: 600;
    font-size: 15px;
    color: var(--dash-head-color);
    margin-bottom: 1.5rem;
    text-align: center;
}

.file-white-con img {
    margin-bottom: 1.5rem;
    width: 75px;
    height: 75px;
}



.file-mangement-con .options-files {
    position: absolute;
    right: -10px;
    top: -10px;
    box-shadow: 0 .3125rem .625rem 0 rgba(0, 0, 0, .12);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
}

.file-mangement-con .dropdown-menu {
    margin-top: 10px !important;
    background-image: linear-gradient(to right, var(--primary-color), #06626e5e);

}

.file-mangement-con .dropdown-menu li {
    color: white;
}

.file-mangement-con .dropdown-menu li:hover {
    color: rgba(255, 255, 255, 0.511);
}

@media all and (max-width:767px) {
    .file-white-con {
        padding: 1rem
    }

    .file-white-con h2 {
        font-size: 7px;
    }

    .file-white-con img {
        margin-bottom: .5rem;
        width: 50px;
        height: 50px;
    }

    .file-mangement-con .options-files {
        padding: 5px;
    }

    .file-mangement-con .options-files svg {
        width: 80%;
        height: 80%;
    }

}