.delete-modal {
    width: 100%;
    height: 100%;
    padding-inline: 20px;
}

.alert-delete .icon {
    color: brown;
    margin-bottom: 10px;
}

.delete-modal h3 {
    font-weight: 500;
    font-size: 25px;
    color: var(--dash-head-color);
    margin-bottom: 10px;
}

.delete-modal p {
    text-align: center;
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
    color: var(--dash-text-primary)
}

.delete-modal .btn-delete,
.delete-modal .btn-cancel {
    padding: 7px 20px;
    border-radius: 7px;
    font-size: 18px;
    width: 80%;
}

.delete-modal .btn-delete {
    background-color: brown;
    color: white;
    margin-bottom: 15px;
    border: none;

    &:hover {
        background-color: rgb(130, 33, 33);
        transition: .3s;
    }
}

.delete-modal .btn-cancel {
    border: 1px solid var(--dash-text-primary);
}