.edit-profile form {
    width: 80%;
}

.edit-profile form .col {
    display: flex;
    align-items: center;
}

.edit-profile div.form-control {
    border: none;
    width: 100%;
}

.edit-profile .btn-form-container {
    width: 86%;
    display: flex;
    justify-content: flex-end;
}

.edit-profile input,
.edit-profile select {
    background-color: #c1d0df;
}

.edit-profile .btn {
    background-color: unset;
    border: none;
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
}

@media all and (max-width:767px) {
    .edit-profile form {
        width: 100%;
    }

    .edit-profile .btn-form-container {
        width: 98%;
    }

    .edit-profile input,
    .edit-profile select {
        font-size: .5rem;
    }

    .edit-profile .btn {
        font-size: 10px;
    }

}