.file-mangement-con {
    width: 96%;
}

@media all and (max-width:767px) {
    .file-mangement-con {
        width: 100%;
        padding-top: 15px;
        padding-inline: 20px;
    }

    .file-mangement-con .box-title {
        font-size: 10px;
    }
}