.modal.ar .modal-loading {
    & span:not(:last-of-type) {
        margin-right: unset;
        margin-left: 10px;
    }
}

.modal-loading {
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 11111111111;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: var(--bs-modal-border-radius);

    & span {
        width: 15px;
        height: 15px;
        background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
        border-radius: 50%;
    }

    & span:not(:last-of-type) {
        margin-right: 10px;
    }

    & span:nth-child(1) {
        animation: loader 1s ease-in-out 0s infinite alternate;
    }

    & span:nth-child(2) {
        animation: loader 1s ease-in-out .5s infinite alternate;
    }

    & span:nth-child(3) {
        animation: loader 1s ease-in-out 1s infinite alternate;
    }

    & span:nth-child(4) {
        animation: loader 1s ease-in-out 1.5s infinite alternate;
    }
}


@keyframes loader {

    0%,
    100% {
        opacity: 1;
        transform: translateY(0px);
    }

    50% {
        opacity: 0.5;
        transform: translateY(-10px);
    }
}