.new-message-box {
    margin: 15px 0;
    padding-left: 20px;
    margin-bottom: 25px !important;
}

.new-message-box p {
    font-size: 1.15em;
    font-weight: 600;
}

.new-message-box h6 {
    font-weight: 700;
    font-size: 12px;
}

.info-tab {
    width: 40px;
    height: 40px;
    position: relative;
    top: 8px;
}

.info-tab {
    float: left;
    margin-left: -23px;
}

.info-tab .icon {
    color: white;
}

.new-message-box-info {
    background: #eeeeee;
    padding: 3px;
    margin: 10px 0;
}

.tip-box-info {
    color: #01579B;
    background: #B3E5FC;
    padding: 12px 8px 12px 26px;
}

.tip-icon-info {
    background: #03A9F4;
}

.load-more-con {
    width: 100%;
}

.notification-btn-load-more {
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
    padding: 5px 20px;
    font-size: 12px;
    color: white;
    border: 0px;
    width: 50%;
}

.notification-btn-load-more:disabled {
    background-image: linear-gradient(to right, #0095a985, #06626e48);
}