.profile-banner .heighlights-box {
    padding: 0.75rem 1rem;
    min-width: 125px;
    border-radius: 0.475rem;
    border: .5px dotted var(--dash-secondary-primary);

}

.profile-banner.ar .heighlights-box:not(:last-of-type) {
    margin-left: 1.5rem;
    margin-right: 0px;
}

.profile-banner .heighlights-box:not(:last-of-type) {
    margin-right: 1.5rem
}

.profile-banner .heighlights-box .success-text {
    width: 100%;
    font-size: 1.3rem;
    font-weight: 700;
    color: var(--dash-head-color);
    margin-bottom: .5rem;
}

.profile-banner .heighlights-box .success-text .success-icon {
    color: green;
}

.profile-banner .heighlights-box .success-text .faild-icon {
    color: red;
}

.profile-banner .heighlights-box p {
    color: var(--dash-secondary-primary);
    font-weight: 600;
    font-size: .8rem;
}

@media all and (max-width:767px) {
    .profile-banner .heighlights-box {
        padding: 10px;
        min-width: 50px;
    }

    .profile-banner .heighlights-box .success-text {
        font-size: 10px;
    }

    .profile-banner .heighlights-box p {
        font-size: 5px;
    }

    .profile-banner.ar .heighlights-box:not(:last-of-type) {
        margin-left: .5rem;
        margin-right: 0px;
    }


    .profile-banner .heighlights-box:not(:last-of-type) {
        margin-right: .5rem;
    }

}