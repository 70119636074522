.sideBar ul li {
    width: 100%;
}

.sideBar ul li a {
    color: var(--dash-text-primary);
    text-decoration: none;
    font-weight: 500;
    font-size: 14px;
    padding-top: .675rem;
    padding-bottom: .675rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    transition: .2s;
    border-radius: .5rem;
}


.sideBar ul li:not(:last-of-type) {
    margin-bottom: 20px;
}

.sideBar.bgSideBar ul li:last-of-type {
    margin-bottom: 30px;
}

.sideBar ul li a.active,
.sideBar ul li a:hover {
    background-color: #fff;
    box-shadow: 0 20px 27px 0 rgba(0, 0, 0, .05);
    font-weight: 600;
}

.sideBar ul li a:hover span,
.sideBar ul li a.active span {
    background-image: linear-gradient(310deg, var(--primary-color), var(--primary-color));

    & svg {
        color: white;
    }
}

.sideBar.ar ul li a span {
    margin-right: 0px;
    margin-left: .5rem;
}

.sideBar ul li a span {
    width: 32px;
    height: 32px;
    background-color: #fff;
    box-shadow: 0 .3125rem .625rem 0 rgba(0, 0, 0, .12);
    border-radius: .5rem;
    margin-right: .5rem;
}

.sideBar ul li a span svg {
    width: 17px;
    height: 17px;
}

@media all and (max-width:767px) {
    .sm-screen-sidebar ul li {
        width: 100%;
    }

    .sm-screen-sidebar ul li a {
        color: var(--dash-text-primary);
        text-decoration: none;
        font-weight: 500;
        font-size: 12px;
        padding-top: .675rem;
        padding-bottom: .675rem;
        transition: .2s;
        border-radius: .5rem;
    }

    .sm-screen-sidebar ul li:not(:last-of-type) {
        margin-bottom: 0px;
    }

    .sm-screen-sidebar ul li a:hover span,
    .sm-screen-sidebar ul li a.active span {
        background-image: linear-gradient(310deg, var(--primary-color), var(--primary-color));

        & svg {
            color: white;
        }
    }

    .sm-screen-sidebar.ar ul li a span {
        margin-right: 0px;
        margin-left: .5rem;
    }

    .sm-screen-sidebar ul li a span {
        width: 22px;
        height: 22px;
        background-color: #fff;
        box-shadow: 0 .3125rem .625rem 0 rgba(0, 0, 0, .12);
        border-radius: .5rem;
        margin-right: .5rem;
    }

    .sm-screen-sidebar ul li a span svg {
        width: 12px;
        height: 12px;
    }
}