.change-password-con div.form-control {
    border: none;
    background: transparent;
}

.change-password-con .col {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.change-password-con .btn-form-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.change-password-con h6 {
    font-weight: 700;
    font-size: 13px;
}

.change-password-con h6 {
    color: var(--dash-secondary-primary);
}

.change-password-con .btn {
    background-color: unset;
    border: none;
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
}

@media all and (max-width:767px) {
    .change-password-con .btn {
        font-size: 10px;
    }

    .change-password-con h6,
    .change-password-con div.form-control input {
        font-size: 10px;
    }
}