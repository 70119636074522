.sm-screen-sidebar,
.close-icon,
.sm-postion-con {
    display: none;
}

@media all and (max-width:767px) {
    .sm-postion-con {
        display: block;
        position: absolute;
        height: 100%;
        z-index: 99;
    }

    .sm-screen-sidebar {
        display: flex;
        position: sticky;
        left: 0px;
        top: 0px;
        width: 85%;
        padding-bottom: 50px;
        background-color: rgb(241, 239, 239);
    }

    .sm-screen-sidebar .sideBar-header .navbar-brand {
        padding: 1.5rem 0;

    }

    .sm-screen-sidebar .sideBar-header .navbar-brand img {
        width: 115px;
    }

    hr.horizontal {
        margin-bottom: 5px;
    }

    .sm-screen-sidebar.ar .close-icon {
        right: unset;
        left: 15px;
    }

    .close-icon {
        display: block;
        position: absolute;
        top: 10px;
        right: 15px;

        & .icon {
            color: var(--primary-color);
        }
    }
}