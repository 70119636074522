.dropdown-menu {
    padding: 20px;
    width: 15%;
    border: none;
    margin-top: 7px !important;
}

.dropdown-menu.ar {
    width: 10%;
}

.dropdown-menu li {
    color: var(--dash-text-primary);
    cursor: pointer;

    &:hover {
        color: var(--dash-head-color);
        transition: .3s;
    }

}

.dropdown-menu li:not(:last-of-type) {
    margin-bottom: 10px;
}

/* Main Nav Dropdown */
.profile-dropdown {
    width: 40px;
    height: 40px;
    border-radius: 0.475rem;
    cursor: pointer;
}

.main-dashboard-nav.ar .languages {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main-dashboard-nav.ar .languages span img {
    margin-right: 0px;
    margin-left: 20px;
}

.main-dashboard-nav .languages span img {
    border-radius: 0.225rem;
    width: 25px;
    height: 25px;
    margin-right: 20px;
    width: fit-content;
}

.main-dashboard-nav .main-languages-li span {
    margin-right: 10px;
}

.main-dashboard-nav.ar .main-languages-li span {
    margin-right: 0px;
    margin-left: 10px;
    width: 15%;
}

.main-dashboard-nav.ar .main-languages-li span img {
    width: 100%;
    height: 100%;
}

.main-dashboard-nav.ar .main-languages-li span img {
    margin-right: 0px;
    margin-left: 10px;
}

.main-dashboard-nav .main-languages-li span img {
    border-radius: 0.225rem;
    width: 10%;
    height: 10%;
}


.main-dashboard-nav.ar .links {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.main-dashboard-nav.ar .main-languages-li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

/* Main Nav Dropdown */

@media all and (max-width:767px) {
    .dropdown-menu {
        font-size: 13px;
    }

    .users .dropdown-menu {
        font-size: 8px;
    }
}