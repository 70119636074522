 .file-label {
     color: var(--dash-secondary-primary);
     font-weight: 700;
     font-size: 14px;
     margin-bottom: 1.25rem
 }

 .file-input-con {
     position: relative;
     width: 125px;
     border-radius: .475rem;
     padding-inline: 20px;
     padding-bottom: 10px;
     box-shadow: var(--bs-box-shadow);
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
 }

 .file-input-con span {
     font-size: 12px;
     width: 100%;
     font-size: 12px;
     word-break: break-all;
     text-align: center;
 }

 .file-input-con img {
     width: 80px;
     height: 80px;
 }

 .file-input-con .change-file-btn {
     position: absolute;
     top: -10px;
     right: -10px;
     border-radius: 50%;
     box-shadow: var(--bs-box-shadow);
     background-color: white;
     border-radius: 50%;
     width: 25px;
     height: 25px;
     cursor: pointer;
 }