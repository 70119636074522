.table-con {
    box-shadow: 0 0 0 9999px transparent
}

.table-con .ant-table-row {
    cursor: pointer;
}

th.ant-table-cell {
    font-size: 10.4px;
    font-family: "Open Sans";
    font-weight: 700;
    text-transform: uppercase;
    color: var(--dash-secondary-primary) !important;
}

td.ant-table-cell {
    font-size: 13px;
    font-weight: 600;
    color: var(--dash-text-primary) !important;
}

.action-table {
    cursor: pointer;
}

.users.ar .user-table-img {
    margin-right: 0px;
    margin-left: 1rem;
}

.user-table-img {
    width: 36px;
    height: 36px;
    border-radius: .75rem;
    margin-right: 1rem
}

.user-table-img+.flex-start-start-col h2 {
    margin: 0px;
    line-height: 1.5;
    font-size: .875rem;
    font-weight: 600;
    color: var(--dash-head-color);
}

.table-con.ar svg {
    transform: rotateY(180deg);
}


@media all and (max-width:767px) {
    .user-table-img {
        width: 20px;
        height: 20px;
        margin-right: .2rem;
    }

    .user-table-img+.flex-start-start-col h2 {
        font-size: 6px;
    }
}