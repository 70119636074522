.holidays-con .recharts-responsive-container {
    height: 300px !important;
}

.tasks-con {
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a);
    padding: 30px;

}

.tasks-con h4 {
    color: white;
}

.tasks {
    width: 90%;
    padding: 30px;
}

.tasks-con .recharts-responsive-container,
.tasks .recharts-responsive-container {
    height: 300px !important;
}

.sales-con-flex {
    display: flex;
    justify-content: space-between;
}

.sales-con-flex-col {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.sales-con-flex-col:nth-child(2) {
    margin-left: 20px;
}

.sales-con-flex-col p {
    color: white;
    display: flex;
    align-items: center;
}



.tasks-con .recharts-responsive-container text {
    fill: white
}