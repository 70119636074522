.loading-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    z-index: 999;
}

.loading-container img {
    width: 15%;
    height: 15%;
    margin-bottom: 30px;
    animation: logoanimation 2s infinite;
}

.loading-container p {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loading-container.ar p .spinner-border {
    margin-left: unset;
    margin-right: 10px;
}

.loading-container p .spinner-border {
    margin-left: 10px;
}

@keyframes logoanimation {

    0%,
    100% {
        transform: scale(1);
    }


    50% {
        transform: scale(1.1);
    }


}