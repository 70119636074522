.login .logo-con {
    width: 155px;
    height: 75px;
    margin-bottom: 3rem;
}

.auth-screens {
    width: 75%;
    height: 60%;
}

.auth-screens img {
    width: 100%;
    height: 100%;
}

@media all and (min-width:768px) and (max-width:1024px) {
    .auth-screens {
        width: 82%;
        height: 32%;
    }

}

@media all and (max-width :767px) {
    .login .right-col {
        display: none;
    }
}