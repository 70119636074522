.box-with-shadow {
    box-shadow: 0 .3125rem .625rem 0 rgba(0, 0, 0, .12);
    border-radius: .5rem;
    width: 98% !important;
    background-color: white !important;
    padding: 30px;
}

.box-with-shadow h4 {
    margin-bottom: 40px;
}

.box-with-shadow.blue {
    background-image: linear-gradient(to right, var(--primary-color), #06626e8a) !important;
    box-shadow: 0 .3125rem .625rem 0 rgba(0, 0, 0, .12);
}

.box-with-shadow.photo {
    width: 150px !important;
    height: 150px;
}

/* Main Page Box */

.main-page-con .employer-of-the-month {
    margin: 0px;
    padding: 30px 1.2rem;
    padding-bottom: 0px;
}

.employer-of-the-month .text h2 {
    font-size: 1.325rem;
    color: var(--bg-white-text);
    margin-bottom: .75rem
}

.employer-of-the-month .text p {
    color: var(--dash-secondary-primary);
    font-size: .8rem;
}

.employer-of-the-month-col {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 100%;
    height: 100%;
}

.employer-of-the-month-col img {
    width: 90%;
    height: 90%;
}

.employee-of-month-img {
    width: 100%;
    height: 100%;
}

/* Main Page Box */


/* Daily Quate */
.daily-quote {
    height: 100%;
    padding: 50px;
    color: var(--dash-text-primary);
}

/* Daily Quate */

/* Word from company  */
.word-from-company {
    color: white;
    padding: 50px;
    height: 100%;
}

.word-from-company img {
    width: 35%;
    height: 35%;
    margin-bottom: 1.5rem;
}

.word-from-company p {
    text-align: center;
    font-weight: 300;
}

/* Word from company  */


/* HOLIDAYS Chart */
.employe-chart-box {
    margin: 0px;
}

.employe-chart-box {
    width: fit-content !important;
}

.employe-chart-box h4 {
    color: white;
}

.employe-chart-box .sales-con-flex {
    justify-content: flex-start;
}

.employe-chart-box .sales-con-flex-col {
    margin-right: 50px;
}

.employe-chart-box .sales-con-flex-col p {
    font-size: 17px;
}

.users .add-user {
    font-weight: 600;
}

/* HOLIDAYS Chart */


/* Users */
.users {
    margin: 0px;
    margin-top: 5.4%;
    padding-bottom: 40px;
}

/* Users */



/* Profile Page */
.profile-details {
    margin: 0px;
    padding: 30px;
    width: 96%;
}

/* Profile Page */


@media all and (max-width:767px) {
    .box-with-shadow h4 {
        margin-bottom: 25px;
        font-size: 14px;
    }

    /* Main Page Box */
    .main-page-con .employer-of-the-month {
        margin: 0px;
        padding: 15px .8rem;
        margin-bottom: 20px;
    }

    .employer-of-the-month .text h2 {
        font-size: 14px;
        margin-bottom: .5rem;
    }

    .employer-of-the-month .text p {
        font-size: 12px;
    }

    .employer-of-the-month-col img {
        width: 100%;
        height: 78%;
    }

    .employee-of-month-img {
        height: 50vh;
        padding: 0px;
    }

    .box-with-shadow.word-from-company {
        width: 100%;
    }

    .word-from-company img {
        width: 25%;
        height: 25%;
        margin-bottom: 1rem;
    }

    .word-from-company p {
        font-size: 12px;
    }

    /* Main Page Box */



    /* Users */

    .employe-chart-box {
        width: unset !important;
    }

    .employe-chart-box h4 {
        margin-bottom: 10px;
    }

    .recharts-wrapper {
        height: 110px !important;
    }

    .employe-chart-box .sales-con-flex-col p {
        font-size: 12px;
    }

    .employe-chart-box .sales-con-flex-col {
        margin-right: 15px;
        width: 42%;
    }

    /* Users */
}