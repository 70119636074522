.text-error {
    font-size: 13px !important;
    font-weight: 400;
}

@media all and (max-width:767px) {
    .text-error {
        font-size: 8px !important;
        margin-top: 5px;
    }
}