.img-input-con {
    border-radius: .475rem;
    box-shadow: var(--bs-box-shadow);
    height: 125px;
    position: relative;
    width: 125px;
    margin-bottom: 1rem !important;
}

.img-label {
    color: var(--dash-secondary-primary);
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 1.25rem;
}

.img-input-con .change-img-btn {
    background-color: #fff;
    border-radius: 50%;
    box-shadow: var(--bs-box-shadow);
    cursor: pointer;
    height: 25px;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 25px;
}