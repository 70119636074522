/* Form Login Start */
.login-form {
    width: 70%;
    border-radius: 7px;
    padding: 10px;
}


.login-form label {
    font-size: 1.075rem !important;
    color: white;
}

.login-form div.form-control {
    padding-left: 0px;
    background-color: transparent;
    border: none;
    padding: 0px;
}

.login-form input {
    height: 40px;
    background-color: white;
}

.login-form input:focus {
    box-shadow: 0 0 10px .25rem var(--secondary-color);
}


.login-form div.form-control:not(:last-of-type) {
    margin-bottom: 1rem;
}

.login-form button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--bg-white) !important;
    color: black;
    border: none;
    margin-top: 3rem;
}

.login-form .btn.disabled,
.login-form .btn:disabled,
.login-form fieldset:disabled .btn {
    color: rgba(0, 0, 0, 0.684);
}

.login-form button:hover {
    color: black;
    background-color: rgba(255, 255, 255, 0.605) !important;
}

.login-form .text-error {
    color: white;
}

/* Form Login End */


/* Form Modal Start */
.modal div.form-control {
    border: none;
    padding-left: 0px;
}

.modal div.form-control:not(:last-of-type) {
    margin-bottom: .5rem
}

.modal div.form-control:last-of-type {
    margin-bottom: 1rem;
}

.modal label {
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 0.5rem;
    color: var(--dash-secondary-primary);
}

.modal .btn-form-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

/* Form Modal End */