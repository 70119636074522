.sideBar {
    position: sticky;
    left: 0px;
    top: 0px;
    height: 100vh;
    padding: 0px;
    padding-bottom: 40px;

    &.bgSideBar {
        height: 100%;
    }
}



.sideBar .sideBar-header .navbar-brand {
    padding: 1.5rem 0;

}

.sideBar .sideBar-header .navbar-brand img {
    width: 130px;
}

hr.horizontal.dark {
    background-image: linear-gradient(90deg, transparent, rgba(0, 0, 0, .4), transparent);
}

hr.horizontal {
    background-color: transparent;
    border-top: none;
    height: 1px;
    width: 100%;
}

.sideBar ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
}

@media all and (max-width:767px) {
    .sideBar {
        display: none;
    }
}